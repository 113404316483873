const ApiUrl = process.env.NEXT_PUBLIC_API_URL;

const UrlParamsReplace = (url, params = {}) => {
  let urlWithPrefix = `${ApiUrl}${url}`;
  if (params) {
    Object.keys(params).forEach(
      (key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
    );
  }
  return urlWithPrefix;
};

//aws
export const GET_PRE_SIGNED_URL = UrlParamsReplace("/aws/get-pre-signed-url");
export const GET_UPLOAD_PRE_SIGNED_URL = UrlParamsReplace(
  "/aws/get-upload-pre-signed-url"
);
export const UPDATE_ACL_PUBLIC_STATUS_URL = UrlParamsReplace(
  "/file/update-acl-public-status"
);

export const SYSTEM_ADMIN_LOGIN_URL = UrlParamsReplace("/login/admin");
export const USER_SESSION = UrlParamsReplace("/user-sessions");
// pods
export const CREATE_HOLIDAYS_URL = (userId) =>
  UrlParamsReplace("/systemAdmins/:userId/holidays", { userId });
export const UPDATE_HOLIDAYS_URL = (userId, holidayId) =>
  UrlParamsReplace("/systemAdmins/:userId/holidays/:holidayId", {
    userId,
    holidayId,
  });
export const REMOVE_HOLIDAYS_URL = (userId, holidayId) =>
  UrlParamsReplace("/systemAdmins/:userId/holidays/:holidayId", {
    userId,
    holidayId,
  });

export const GET_HOLIDAYS_URL = (userId) =>
  UrlParamsReplace("/systemAdmins/:userId/holidays", { userId });
export const GET_PODS_URL = (userId, offset, limit, search, podStatus) =>
  UrlParamsReplace(
    "/systemAdmins/:userId/pods?offset=:offset&limit=:limit&search=:search&isActive=:podStatus",
    { userId, podStatus, offset, limit, search }
  );
export const ADD_MENU_URL = (userId) =>
  UrlParamsReplace("/systemAdmins/:userId/menuItems", { userId });
export const UPDATE_MENU_URL = (userId) =>
  UrlParamsReplace("/systemAdmins/:userId/menuItems", { userId });
export const GET_SCHOOL_ONBOARD_LIST_URL = (adminId, search) =>
  UrlParamsReplace(
    "/v3/systemAdmins/:adminId/school-onboard-request?search=:search",
    {
      adminId,
      search,
    }
  );
export const ADD_MENU_v3_URL = (userId) =>
  UrlParamsReplace("/v3/systemAdmins/:userId/menuItems", { userId });

export const PUBLISH_MENU_DATE_URL = (userId, date) =>
  UrlParamsReplace("/systemAdmins/:userId/menus/:date/publish", {
    userId,
    date,
  });

export const UPDATE_MENU_V3_URL = (userId) =>
  UrlParamsReplace("/v3/systemAdmins/:userId/menuItems", { userId });
export const GET_SCHOOL_HOLIDAYS_URL = (adminId, schoolId) =>
  UrlParamsReplace("/systemAdmins/:adminId/schools/:schoolId/holidays", {
    adminId,
    schoolId,
  });
export const GET_POD_HOLIDAYS_URL = (adminId, podId) =>
  UrlParamsReplace("/systemAdmins/:adminId/pods/:podId/holidays", {
    adminId,
    podId,
  });
export const GET_SCHOOL_HOLIDAYS_BY_PODID_URL = (adminId, podId) =>
  UrlParamsReplace("/systemAdmins/:adminId/pods/:podId/schoolholidays", {
    adminId,
    podId,
  });
export const GET_SCHOOLS_BY_PODID = (userId, podId) =>
  UrlParamsReplace("/systemAdmins/:userId/allSchools?podId=:podId", {
    userId,
    podId,
  });
export const CREATE_POD_HOLIDAYS_URL = (adminId, podId) =>
  UrlParamsReplace("/systemAdmins/:adminId/pods/:podId/holidays", {
    adminId,
    podId,
  });
export const CREATE_AUTO_ORDER_HOLIDAY = (adminId) =>
  UrlParamsReplace("/systemAdmins/:adminId/schoolsholidays", {
    adminId,
  });
export const CREATE_SCHOOL_HOLIDAYS_URL = (adminId, schoolId) =>
  UrlParamsReplace("/systemAdmins/:adminId/schools/:schoolId/holidays", {
    adminId,
    schoolId,
  });
export const CREATE_BLOCK_URL = (adminId) =>
  UrlParamsReplace("/systemAdmins/:adminId/schoolblocks", {
    adminId,
  });
export const UPDATE_POD_HOLIDAY_URL = (adminId, podId, holidayId) =>
  UrlParamsReplace("/systemAdmins/:adminId/pods/:podId/holidays/:holidayId", {
    adminId,
    podId,
    holidayId,
  });
export const UPDATE_SCHOOL_POD_HOLIDAY_URL = (adminId, schoolId, holidayId) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/schools/:schoolId/holidays/:holidayId",
    {
      adminId,
      schoolId,
      holidayId,
    }
  );
export const UPDATE_SCHOOL_HOLIDAY_URL = (adminId, schoolId, holidayId) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/schools/:schoolId/holidays/:holidayId",
    {
      adminId,
      schoolId,
      holidayId,
    }
  );
export const REMOVE_POD_HOLIDAY_URL = (adminId, podId, holidayId) =>
  UrlParamsReplace("/systemAdmins/:adminId/pods/:podId/holidays/:holidayId", {
    adminId,
    podId,
    holidayId,
  });
export const REMOVE_SCHOOL_POD_HOLIDAY_URL = (userId, schoolId, holidayId) =>
  UrlParamsReplace(
    "/systemAdmins/:userId/schools/:schoolId/holidays/:holidayId",
    {
      userId,
      schoolId,
      holidayId,
    }
  );
export const REMOVE_SCHOOL_HOLIDAY_URL = (adminId, schoolId, holidayId) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/schools/:schoolId/holidays/:holidayId",
    {
      adminId,
      schoolId,
      holidayId,
    }
  );
export const REMOVE_SCHOOL_BLOCK = (adminId, schoolId) =>
  UrlParamsReplace("/systemAdmins/:adminId/schoolblocks/:schoolId", {
    adminId,
    schoolId,
  });
export const REMOVE_ALL_SCHOOL_BLOCK = (adminId, schoolId) =>
  UrlParamsReplace("/systemAdmins/:adminId/schoolblocks/school/:schoolId", {
    adminId,
    schoolId,
  });
export const GET_POD_KITCHEN_MAPPING_URL = (
  adminId,
  podId,
  offset,
  limit,
  search,
  isActive
) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/pods/:podId/subItemKitchenMappings/?offset=:offset&limit=:limit&search=:search&isActive=:isActive",
    { adminId, podId, offset, limit, search, isActive }
  );
export const GET_POD_KITCHEN_MAPPING_BY_ID_URL = (adminId, podId, mappindId) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/pods/:podId/subItemKitchenMappings/:mappindId",
    { adminId, podId, mappindId }
  );
export const CREATE_POD_KITCHEN_MAPPING_URL = (adminId, podId) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/pods/:podId/subItemKitchenMappings",
    { adminId, podId }
  );
export const GET_POD_BY_ID = (adminId, podId) =>
  UrlParamsReplace("/systemAdmins/:adminId/pods/:podId/", { adminId, podId });
export const COPY_POD_KITCHEN_MAPPING = (adminId, fromPodId, toPodId) =>
  UrlParamsReplace("/systemAdmins/:adminId/copyKitchenMapping", {
    adminId,
    fromPodId,
    toPodId,
  });
export const DELETE_MENU_BY_ID_URL = (adminId, menuId) =>
  UrlParamsReplace("/systemAdmins/:adminId/menus/:menuId", { adminId, menuId });
export const GET_ALL_ACTIVE_PODS = (adminId) =>
  UrlParamsReplace("/systemAdmins/:adminId/activePods", { adminId });

export const UPDATE_SUBITEM_KITCHEN = (userId, podId) =>
  UrlParamsReplace("/systemAdmins/:userId/pod/:podId/kitchen-subitem-mapping", {
    userId,
    podId,
  });

// school
export const ADD_SCHOOL_URL = (userId) =>
  UrlParamsReplace("/systemAdmins/:userId/schools", { userId });
export const GET_SCHOOLS_URL = (userId, offset, limit, search, podId) =>
  UrlParamsReplace(
    "/systemAdmins/:userId/schools?offset=:offset&limit=:limit&search=:search&podId=:podId",
    { userId, offset, limit, search, podId }
  );

export const GET_B2B_SCHOOLS_SCHOOLS_URL = (
  userId,
  offset,
  limit,
  search,
  podId
) =>
  UrlParamsReplace(
    "/systemAdmins/:userId/offlinebtb-schools?offset=:offset&limit=:limit&search=:search&podId=:podId",
    { userId, offset, limit, search, podId }
  );

export const GET_SCHOOLS_B2B_ORDER_REPORT_URL = (
  userId,
  schoolId,
  date,
  monthly
) =>
  UrlParamsReplace(
    "/systemAdmins/:userId/orderreports/schools/:schoolId/:date?monthly=:monthly",
    { userId, schoolId, date, monthly }
  );

export const GET_SCHOOL_CHAINS_URL = (
  userId,
  offset,
  limit,
  search,
  isActive
) =>
  UrlParamsReplace(
    "/systemAdmins/:userId/schoolChains?offset=:offset&limit=:limit&search=:search&isActive=:isActive",
    { userId, offset, limit, search, isActive }
  );
export const GET_SCHOOL_CHAIN_BY_ID_URL = (userId, schoolChainId) =>
  UrlParamsReplace("/systemAdmins/:userId/schoolChains/:schoolChainId", {
    userId,
    schoolChainId,
  });
export const UPDATE_SCHOOL_CHAIN_URL = (userId, schoolChainId) =>
  UrlParamsReplace("/systemAdmins/:userId/schoolChains/:schoolChainId", {
    userId,
    schoolChainId,
  });
export const ADD_SCHOOL_CHAIN_URL = (userId) =>
  UrlParamsReplace("/systemAdmins/:userId/schoolChains", { userId });
export const GET_SCHOOL_URL = (adminId, schoolId) =>
  UrlParamsReplace("/systemAdmins/:adminId/schools/:schoolId", {
    adminId,
    schoolId,
  });
export const UPDATE_SCHOOL_URL = (adminId, schoolId) =>
  UrlParamsReplace("/systemAdmins/:adminId/schools/:schoolId", {
    adminId,
    schoolId,
  });
export const GET_ALL_AGE_GROUPS_FOR_SCHOOL = () =>
  UrlParamsReplace("/systemAdmins/ageGroups");
export const GET_ORDER_DETAILS_FOR_SCHOOL_ID = (schoolId, mealType, date) =>
  UrlParamsReplace(
    "/systemAdmins/orderDetails/:schoolId?mealType=:mealType&date=:date",
    { schoolId, mealType, date }
  );
export const GET_SCHOOL_BY_ID = (schoolId) =>
  UrlParamsReplace("/systemAdmins/schools/:schoolId", { schoolId });
export const GET_ALL_ACTIVE_SCHOOLS = (userId) =>
  UrlParamsReplace("/systemAdmins/:userId/allSchools", { userId });
export const GET_ALL_ACTIVE_RULES = (adminId) =>
  UrlParamsReplace("/systemAdmins/:adminId/auto-order-holidays/active", {
    adminId,
  });
export const GET_ALL_PAST_RULES = (adminId) =>
  UrlParamsReplace("/systemAdmins/:adminId/auto-order-holidays/past", {
    adminId,
  });
export const CANCEL_AUTO_ORDER_HOLIDAY = (adminId, autoOrderHolidayId) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/auto-order-holiday/:autoOrderHolidayId",
    {
      adminId,
      autoOrderHolidayId,
    }
  );
export const GET_ALL_PAST_ORDERS = (
  userId,
  offset,
  limit,
  startDate,
  endDate,
  schoolId
) =>
  UrlParamsReplace(
    "/systemAdmins/:userId/pastOrders?offset=:offset&limit=:limit&startDate=:startDate&endDate=:endDate&schoolId=:schoolId",
    { userId, offset, limit, startDate, endDate, schoolId }
  );

// user & roles
export const CREATE_USER = (userId) =>
  UrlParamsReplace("/systemAdmins/:userId/users", { userId });
export const GET_ROLE_USER = (adminId, roleId) =>
  UrlParamsReplace("/systemAdmins/:adminId/roles/:roleId/users", {
    adminId,
    roleId,
  });
export const GET_USERS_URL = (adminId, offset, limit, search) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/users?offset=:offset&limit=:limit&search=:search",
    { adminId, offset, limit, search }
  );
export const GET_ROLES = () => UrlParamsReplace("/roles");
export const GET_SINGLE_USER_URL = (userId, id) =>
  UrlParamsReplace("/systemAdmins/:userId/users/:id", { userId, id });
export const UPDATE_ADMIN_USER_URL = (userId, id) =>
  UrlParamsReplace("/systemAdmins/:userId/users/:id", { userId, id });
export const GET_ALL_USER_DETAILS = (userId, offset, limit, date) =>
  UrlParamsReplace(
    "/systemAdmins/:userId/userDetails?offset=:offset&limit=:limit&date=:date",
    { userId, offset, limit, date }
  );
export const GET_ALL_TRANSACTION_DETAILS = (userId, offset, limit, search) =>
  UrlParamsReplace(
    "/systemAdmins/:userId/transactionDetails?offset=:offset&limit=:limit&search=:search",
    { userId, offset, limit, search }
  );

// school b2b price
export const CREATE_PRICE_B2B_SCHOOL = (adminId, schoolId) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/school-offline-btb/schools/:schoolId",
    { adminId, schoolId }
  );
export const GET_B2B_OFFLINE_PRICE = (adminId, schoolId) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/school-offline-btb/schools/:schoolId",
    { adminId, schoolId }
  );

// Refund

export const GET_ALL_REFUND_REQUESTS = (adminId, status) =>
  UrlParamsReplace(
    `${
      status == ""
        ? "/systemAdmins/:adminId/refund/requests"
        : "/systemAdmins/:adminId/refund/requests?status=:status"
    }`,
    {
      adminId,
      status,
    }
  );
export const APPROVE_REFUND_REQUEST = (adminId, refundRequestId) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/refund/requests/:refundRequestId/approve",
    { adminId, refundRequestId }
  );
export const APPROVE_FAILED_REFUND_REQUEST = (adminId, refundRequestId) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/refund/requests/:refundRequestId/mark-complete",
    { adminId, refundRequestId }
  );
export const GET_ALL_REFUND_TRANSACTIONS = (adminId) =>
  UrlParamsReplace("/systemAdmins/:adminId/refund/request/transactions", {
    adminId,
  });
//podAdmin
export const GET_TEAM_DETAILS_BY_POD_ID = (adminId, podId) =>
  UrlParamsReplace("/podAdmins/:adminId/teamDetails/:podId", {
    adminId,
    podId,
  });
export const GET_MENUS_WITH_ORDER_COUNT_BY_POD_ID = (
  adminId,
  podId,
  offset,
  limit,
  search
) =>
  UrlParamsReplace(
    "/podAdmins/:adminId/menusWithOrderCount/:podId?offset=:offset&limit=:limit&search=:search",
    { adminId, podId, offset, limit, search }
  );
export const GET_DELIVERY_DETAILS = (adminId, podId, date, mealType) =>
  UrlParamsReplace(
    "/podAdmins/:adminId/deliveryDetails?podId=:podId&date=:date&mealType=:mealType",
    { adminId, podId, date, mealType }
  );
export const GET_SCHOOLS_LIST_BY_POD_ID_URL = (
  adminId,
  podId,
  date,
  mealType
) =>
  UrlParamsReplace(
    "/podAdmins/:adminId/podSchools?podId=:podId&date=:date&mealType=:mealType",
    { adminId, podId, date, mealType }
  );
export const GET_SCHOOLS_PACKAGING_DETAILS = (
  adminId,
  schoolId,
  date,
  mealType
) =>
  UrlParamsReplace(
    "/podAdmins/:adminId/packagingDetails?schoolId=:schoolId&date=:date&mealType=:mealType",
    { adminId, schoolId, date, mealType }
  );
export const GET_ORDER_DETAILS_BY_POD_ID = (
  adminId,
  podId,
  mealType,
  date,
  ageGroupId
) =>
  UrlParamsReplace(
    "/podAdmins/:adminId/orderDetails/:podId?mealType=:mealType&date=:date&ageGroupId=:ageGroupId",
    { adminId, podId, mealType, date, ageGroupId }
  );
export const GET_POD_IDS_BY_ADMIN_ID = (adminId, roleName) =>
  UrlParamsReplace("/podAdmins/:adminId/podIds/:roleName", {
    adminId,
    roleName,
  });
export const GET_POD_IDS_BY_CHEF_ADMIN_ID = (adminId, roleName) =>
  UrlParamsReplace("/chefAdmins/:adminId/podIds/:roleName", {
    adminId,
    roleName,
  });
export const GET_ALL_AGE_GROUPS_FOR_POD = (adminId) =>
  UrlParamsReplace("/podAdmins/:adminId/ageGroups", { adminId });
export const GET_TOTAL_ORDER_DETAILS_BY_POD = (
  adminId,
  podId,
  mealType,
  date
) =>
  UrlParamsReplace(
    "/podAdmins/:adminId/totalOrderDetails/:podId?mealType=:mealType&date=:date",
    { adminId, podId, mealType, date }
  );
export const GET_ROUTE_ORDER_DETAILS = (adminId, date) =>
  UrlParamsReplace("/packagingAdmins/:adminId/allRouteOrders?date=:date", {
    adminId,
    date,
  });
//  kitchen
export const GET_KITCHENS_URL = (
  userId,
  offset,
  limit,
  search,
  kitchenStatus
) =>
  UrlParamsReplace(
    "/systemAdmins/:userId/kitchens?offset=:offset&limit=:limit&search=:search&isActive=:kitchenStatus",
    { userId, offset, limit, search, kitchenStatus }
  );
export const ADD_KITCHEN_URL = (userId) =>
  UrlParamsReplace("/systemAdmins/:userId/kitchens", { userId });
export const GET_KITCHEN_BY_ID_URL = (userId, kitchenId) =>
  UrlParamsReplace("/systemAdmins/:userId/kitchens/:kitchenId", {
    userId,
    kitchenId,
  });
export const UPDATE_KITCHEN_URL = (userId, kitchenId) =>
  UrlParamsReplace("/systemAdmins/:userId/kitchens/:kitchenId", {
    userId,
    kitchenId,
  });
//cusines
export const GET_CUISINES = () => UrlParamsReplace("/cuisines");

//food
export const ADD_SUB_ITEM = (userId) =>
  UrlParamsReplace("/systemAdmins/:userId/subItems", { userId });
export const GET_SUB_ITEMS_FOR_MENU = (userId, isActive, podId) =>
  UrlParamsReplace(
    "/systemAdmins/:userId/menu/subItems?isActive=:isActive&podId=:podId",
    { userId, isActive, podId }
  );
export const GET_SUB_ITEMS = (userId, offset, limit, search) =>
  UrlParamsReplace(
    "/systemAdmins/:userId/subItems?offset=:offset&limit=:limit&search=:search",
    { userId, offset, limit, search }
  );
export const GET_POD_MENU = (userId, podId, date, mealType) =>
  UrlParamsReplace(
    "/systemAdmins/:userId/menus/pod/:podId?date=:date&mealType=:mealType",
    { userId, podId, date, mealType }
  );
export const GET_ALL_MENUS_FOR_ALL_POD = (userId, date, mealType) =>
  UrlParamsReplace(
    "/systemAdmins/:userId/menus-with-pods?date=:date&mealType=:mealType",
    { userId, date, mealType }
  );

export const GET_OMAK_MENUS = (userId, search, offset, limit) =>
  UrlParamsReplace(
    "/systemAdmins/:userId/get-omak-menus?offset=:offset&limit=:limit&search=:search",
    { userId, search, offset, limit }
  );
export const CLEAR_POD_MENU = (userId, podId, date, mealType) =>
  UrlParamsReplace(
    "/systemAdmins/:userId/menus/clear/pod/:podId?date=:date&mealType=:mealType",
    { userId, podId, date, mealType }
  );
export const GET_SUB_ITEM = (userId, id) =>
  UrlParamsReplace("/systemAdmins/:userId/subItems/:id", { userId, id });
export const UPDATE_SUB_ITEM = (userId, id) =>
  UrlParamsReplace("/systemAdmins/:userId/subItems/:id", { userId, id });

// NUTRITIONS
export const GET_NUTRITIONS_URL = (adminId, offset, limit, search, isActive) =>
  UrlParamsReplace(
    "/nutritionists/:adminId/nutritions?offset=:offset&limit=:limit&search=:search&isActive=:isActive",
    { adminId, offset, limit, search, isActive }
  );
export const CREATE_NUTRITIONS_URL = (adminId, subItemId) =>
  UrlParamsReplace("/nutritionists/:adminId/nutritions/:subItemId", {
    adminId,
    subItemId,
  });
export const GET_NUTRITIONS_BY_ID_URL = (adminId, subItemId) =>
  UrlParamsReplace("/nutritionists/:adminId/nutritions/:subItemId", {
    adminId,
    subItemId,
  });
export const GET_NUTRITION_PODS = (adminId, offset, limit, search) =>
  UrlParamsReplace(
    "/nutritionists/:adminId/nutritionPods?offset=:offset&limit=:limit&search=:search",
    { adminId, offset, limit, search }
  );
export const GET_NUTRITION_SUBITEM_MENU = (adminId, menuId) =>
  UrlParamsReplace("/nutritionists/:adminId/subItemMenus/:menuId", {
    adminId,
    menuId,
  });
export const GET_NUTRITION_POD_MENU = (adminId, podId, date, mealType) =>
  UrlParamsReplace(
    "/nutritionists/:adminId/menus/pod/:podId?date=:date&mealType=:mealType",
    { adminId, podId, date, mealType }
  );
export const GET_NUTRITION_AGE_GROUPS = (adminId) =>
  UrlParamsReplace("/nutritionists/:adminId/ageGroups", { adminId });
export const UPSERT_PORTIONS = (adminId) =>
  UrlParamsReplace("/nutritionists/:adminId/portions", { adminId });
export const GET_PORTIONS = (adminId) =>
  UrlParamsReplace("/nutritionists/:adminId/portions", { adminId });
export const UPLOAD_CSV = (adminId) =>
  UrlParamsReplace("/nutritionists/:adminId/uploadCsv", { adminId });
export const GET_ALL_MENUS_FOR_ALL_POD_NUTRITIONS = (userId, date, mealType) =>
  UrlParamsReplace(
    "/nutritionists/:userId/menus-with-pods?date=:date&mealType=:mealType",
    { userId, date, mealType }
  );
//settings
export const ADD_AGE_GROUP = (userId, startAge, endAge) =>
  UrlParamsReplace("/systemAdmins/:userId/ageGroups", {
    userId,
    startAge,
    endAge,
  });
export const GET_AGE_GROUPS = (userId) =>
  UrlParamsReplace("/systemAdmins/:userId/ageGroups", { userId });
export const DELETE_AGE_GROUP = (userId, ageGroupId) =>
  UrlParamsReplace("/systemAdmins/:userId/ageGroups/:ageGroupId", {
    userId,
    ageGroupId,
  });
export const GET_SETTINGS = (userId) =>
  UrlParamsReplace("/systemAdmins/:userId/settings", { userId });
export const UPDATE_SETTINGS = (userId, settingId) =>
  UrlParamsReplace("/systemAdmins/:userId/settings/:settingId", {
    userId,
    settingId,
  });
export const GET_DEFAULT_MENU_PRICES = (userId) =>
  UrlParamsReplace("/systemAdmins/:userId/defaultMenuPrices", { userId });
export const UPDATE_DEFAULT_MENU_PRICE = (userId, defaultMenuPriceId) =>
  UrlParamsReplace(
    "/systemAdmins/:userId/defaultMenuPrices/:defaultMenuPriceId",
    { userId, defaultMenuPriceId }
  );
export const CREATE_SETTINGS_URL = (userId) =>
  UrlParamsReplace("/systemAdmins/:userId/settings", { userId });
export const ADD_CASHBACK_SETTING_URL = (userId) =>
  UrlParamsReplace("/systemAdmins/:userId/settings/add-cashback", { userId });
export const PODS_MENUS_URL = (adminId, startDate, endDate) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/menus?startDate=:startDate&endDate=:endDate",
    { adminId, startDate, endDate }
  );
export const PODS_MENUS_WITH_DATES_URL = (adminId, startDate, endDate) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/menus-with-pods-dates?startDate=:startDate&endDate=:endDate",
    { adminId, startDate, endDate }
  );
export const DELETE_SETTINGS_URL = (userId, settingId) =>
  UrlParamsReplace("/systemAdmins/:userId/settings/:settingId", {
    userId,
    settingId,
  });
export const GET_ALL_ACTIVE_ORDERS = (adminId, date) =>
  UrlParamsReplace("/systemAdmins/:adminId/orders/date/:date", {
    adminId,
    date,
  });
export const GET_ALL_DELIVERED_ORDERS = (adminId, podId) =>
  // /systemAdmins/:adminId/orders/all/delivered?date=:date
  UrlParamsReplace("/systemAdmins/:adminId/orders/all/delivered?podId=:podId", {
    adminId,
    podId,
  });

export const CANCEL_ALL_ACTIVE_ORDERS = (adminId) =>
  UrlParamsReplace("/systemAdmins/:adminId/order/cancelAll", {
    adminId,
  });

//kitchenAdmin

export const GET_KITCHEN_IDS_BY_ADMIN_ID = (adminId, roleName) =>
  UrlParamsReplace("/kitchenAdmins/:adminId/kitchenNames", {
    adminId,
    roleName,
  });
export const GET_ORDER_DETAILS_BY_KITCHEN_ID = (
  adminId,
  kitchenId,
  date,
  mealType,
  offset,
  limit
) =>
  UrlParamsReplace(
    "/kitchenAdmins/:adminId/orders/:kitchenId?date=:date&mealType=:mealType&offset=:offset&limit=:limit",
    { adminId, kitchenId, date, mealType, offset, limit }
  );
export const GET_PODS_BY_KITCHEN_BY_ID = (adminId, kitchenId, date) =>
  UrlParamsReplace("/kitchenAdmins/:adminId/podDetails/:kitchenId?date=:date", {
    adminId,
    kitchenId,
    date,
  });
export const GET_POD_ORDER_DETAILS_BY_KITCHEN_ID = (
  adminId,
  kitchenId,
  podId,
  date,
  mealType,
  offset,
  limit
) =>
  UrlParamsReplace(
    "/kitchenAdmins/:adminId/podOrders/:kitchenId?date=:date&mealType=:mealType&offset=:offset&limit=:limit&podId=:podId",
    { adminId, kitchenId, podId, date, mealType, offset, limit }
  );

//Logistics Team
export const GET_VAN_LOCATION_LOGISTIC_URL = (adminId) =>
  UrlParamsReplace("/logistics/:adminId/location-trackers/latest", {
    adminId,
  });
// export const GET_SCHOOLS_ROUTE_LOGISTIC_URL = (adminId) =>
//   UrlParamsReplace("/logistics/:adminId/deliveryRoutes/schools/all", {
//     adminId,
//   });
export const GET_SCHOOLS_ROUTE_LOGISTIC_URL = (adminId, date) =>
  UrlParamsReplace("/logistics/:adminId/deliveryRoutes/allSchool?date=:date", {
    adminId,
    date,
  });
export const GET_LOGISTIC_TEAM_POD_ID = (adminId, roleName) =>
  UrlParamsReplace("/logistics/:adminId/podIds/:roleName", {
    adminId,
    roleName,
  });
export const GET_UNROUTED_SCHOOLS_LIST = (adminId, podId, date, mealType) =>
  UrlParamsReplace(
    "/logistics/:adminId/unRoutedSchools?podId=:podId&date=:date&mealType=:mealType",
    { adminId, podId, date, mealType }
  );
export const GET_DELEVERY_ROUTE_LIST = (adminId, podId, date, mealType) =>
  UrlParamsReplace(
    "/logistics/:adminId/deliveryRoutes?podId=:podId&date=:date&mealType=:mealType",
    { adminId, podId, date, mealType }
  );
export const DELETE_DELIVERY_ROUTE = (
  adminId,
  deliveryRouteId,
  numberOfSchools
) =>
  UrlParamsReplace(
    "/logistics/:adminId/deliveryRoutes/:deliveryRouteId/:numberOfSchools",
    { adminId, deliveryRouteId, numberOfSchools }
  );
export const CREATE_DELIVERY_ROUTE = (adminId) =>
  UrlParamsReplace("/logistics/:adminId/deliveryRoutes", { adminId });
export const UPDATE_DELIVERY_ROUTE = (adminId, deliveryRouteId, schoolId) =>
  UrlParamsReplace(
    "/logistics/:adminId/deliveryRoutes/:deliveryRouteId/schools/:schoolId",
    { adminId, deliveryRouteId, schoolId }
  );
export const GET_ALL_ROUTE_ORDERS = (
  adminId,
  offset,
  limit,
  date,
  mealType,
  podId
) =>
  UrlParamsReplace(
    "/logistics/:adminId/routeDetails?offset=:offset&limit=:limit&date=:date&mealtype=:mealType&podId=:podId",
    { adminId, offset, limit, podId, date, mealType }
  );
export const GET_UNROUTED_PERMANENT_SCHOOL_LIST = (
  adminId,
  offset,
  limit,
  podId
) =>
  UrlParamsReplace(
    "/logistics/:adminId/fixedUnRoutedSchools?offset=:offset&limit=:limit&podId=:podId",
    { adminId, offset, limit, podId }
  );
export const CREATE_PERMANENT_DELIVERY_ROUTE = (adminId) =>
  UrlParamsReplace("/logistics/:adminId/permanentDeliveryRoutes", { adminId });
export const GET_ALL_PERMANENT_DELIVERY_ROUTE = (adminId, podId) =>
  UrlParamsReplace("/logistics/:adminId/permanentDeliveryRoutes?podId=:podId", {
    adminId,
    podId,
  });
export const GET_ALL_MISSING_ORDERS_LOGISTIC = (adminId) =>
  UrlParamsReplace("/logisticsTeam/:adminId/orders/missing", {
    adminId,
  });
export const DELETE_FIXED_DELIVERY_ROUTE = (adminId, fixedDeliveryRouteId) =>
  UrlParamsReplace(
    "/logistics/:adminId/permanentDeliveryRoutes/:fixedDeliveryRouteId",
    { adminId, fixedDeliveryRouteId }
  );
export const GET_PERMANENT_DELIVERY_ROUTE = (adminId, fixedDeliveryRouteId) =>
  UrlParamsReplace(
    "/logistics/:adminId/permanentDeliveryRoutes/:fixedDeliveryRouteId",
    { adminId, fixedDeliveryRouteId }
  );
export const UPDATE_FIXED_DELIVERY_ROUTE = (adminId, fixedDeliveryRouteId) =>
  UrlParamsReplace(
    "/logistics/:adminId/permanentDeliveryRoutes/:fixedDeliveryRouteId",
    { adminId, fixedDeliveryRouteId }
  );
export const GET_DELIVERY_ROUTES = (adminId, deliveryRouteId) =>
  UrlParamsReplace("/logistics/:adminId/deliveryRoutes/:deliveryRouteId", {
    adminId,
    deliveryRouteId,
  });
export const UPDATE_DELIVERY_ROUTES = (adminId, deliveryRouteId) =>
  UrlParamsReplace("/logistics/:adminId/deliveryRoutes/:deliveryRouteId", {
    adminId,
    deliveryRouteId,
  });
export const GET_ALL_NOTIFICATIONS_LOGISTIC = (adminId) =>
  UrlParamsReplace("/logistics/:adminId/today-notifications", {
    adminId,
  });
export const MARK_NOTIFICATIONS_READ_LOGISTIC = (adminId) =>
  UrlParamsReplace("/logistics/:adminId/mark-today-notifications", {
    adminId,
  });
export const GET_ALL_DELIVERIES_LOGISTICS = (
  parentId,
  offset,
  limit,
  schoolId,
  date,
  mealType,
  podId
) =>
  UrlParamsReplace(
    "/logistics/:parentId/deliveries?offset=:offset&limit=:limit&schoolId=:schoolId&date=:date&mealtype=:mealType&podId=:podId",
    { parentId, offset, limit, schoolId, date, mealType, podId }
  );
export const GET_ALL_SCHOOLS_LOGISTICS = (adminId) =>
  UrlParamsReplace("/logistics/:adminId/schools", {
    adminId,
  });
export const GET_ALL_MENUS_LOGISTICS = (adminId, date) =>
  UrlParamsReplace("/logistics/:adminId/menus/date/:date", {
    adminId,
    date,
  });
export const DOWNLOAD_LABELS_FOR_ORDERS_LOGISTICS = (
  adminId,
  schoolId,
  date,
  mealtype,
  dispatchStatus
) =>
  UrlParamsReplace(
    "/logistics/:adminId/schools/:schoolId/delivery/reports?date=:date&mealtype=:mealtype&dispatchStatus=:dispatchStatus",
    {
      adminId,
      schoolId,
      date,
      mealtype,
      dispatchStatus,
    }
  );

/****chef Team */
export const GET_POD_IDS_BY_POD_ADMIN_ID = (adminId, roleName) =>
  UrlParamsReplace("/podAdmins/:adminId/podIds/:roleName", {
    adminId,
    roleName,
  });
export const GET_SUB_ITEMS_WITH_ORDER_COUNT_BY_POD_ID = (
  adminId,
  podId,
  date,
  mealType
) =>
  UrlParamsReplace(
    "/chefAdmins/:adminId/subItemDetails/:podId?date=:date&mealType=:mealType",
    { adminId, podId, date, mealType }
  );

//Packaging Team Admin
export const GET_ORDER_DETAILS_FOR_PACKAGING_TEAM_BY_POD_ID = (
  adminId,
  podId,
  mealType,
  date,
  ageGroupId
) =>
  UrlParamsReplace(
    "/packagingAdmins/:adminId/orderDetails/:podId?mealType=:mealType&date=:date&ageGroupId=:ageGroupId",
    { adminId, podId, mealType, date, ageGroupId }
  );
// export const GET_SCHOOLS_PACKAGING_URL = (userId, offset, limit, search) =>
//   UrlParamsReplace("/packagingTeamAdmins/:adminId/deliveryRoutes/schools/all", {
//     userId,
//     offset,
//     limit,
//     search,
//   });
export const GET_SCHOOLS_PACKAGING_URL = (adminId, date) =>
  UrlParamsReplace(
    "/packagingAdmins/:adminId/deliveryRoutes/schools/all?date=:date",
    {
      adminId,
      date,
    }
  );

export const GET_VAN_LOCATION_PACKAGING_URL = (adminId) =>
  UrlParamsReplace("/packagingAdmins/:adminId/location-trackers/latest", {
    adminId,
  });

export const GET_PACKAGING_DETAILS_BY_SCHOOL_ID = (
  adminId,
  schoolId,
  podId,
  date,
  mealType
) =>
  UrlParamsReplace(
    "/packagingAdmins/:adminId/packagingDetails/:podId?schoolId=:schoolId&date=:date&mealType=:mealType",
    { adminId, schoolId, podId, date, mealType }
  );
export const GET_POD_IDS_FOR_PACKAGING_TEAM_BY_ADMIN_ID = (adminId, roleName) =>
  UrlParamsReplace("/packagingAdmins/:adminId/podIds/:roleName", {
    adminId,
    roleName,
  });
export const GET_ALL_AGE_GROUPS = (adminId) =>
  UrlParamsReplace("/packagingAdmins/:userId/ageGroups", { adminId });
export const UPLOAD_PDF_DETAILS = (
  adminId,
  schoolId,
  podId,
  mealType,
  schoolName,
  date,
  tableRange
) =>
  UrlParamsReplace(
    "/packagingAdmins/:adminId/reports?schoolId=:schoolId&podId=:podId&mealType=:mealType&schoolName=:schoolName&date=:date&tableRange=:tableRange",
    { adminId, schoolId, podId, mealType, schoolName, date, tableRange }
  );

export const GET_ALL_PACKAGING_REPORT = (adminId, offset, limit, search) =>
  UrlParamsReplace(
    "/packagingAdmins/:adminId/reports?offset=:offset&limit=:limit&search=:search",
    { adminId, offset, limit, search }
  );

export const GET_ALL_NOTIFICATIONS = (adminId) =>
  UrlParamsReplace("/packagingAdmins/:adminId/today-notifications", {
    adminId,
  });

export const MARK_NOTIFICATIONS_READ = (adminId) =>
  UrlParamsReplace("/packagingAdmins/:adminId/mark-today-notifications", {
    adminId,
  });

export const GET_ALL_MISSING_ORDERS_PACKAGING = (adminId) =>
  UrlParamsReplace("/packagingAdmins/:adminId/orders/missing", { adminId });
export const GET_ALL_ORDERS_SUMMARY = (
  adminId,
  mealType,
  podId,
  date,
  ageGroupId
) =>
  UrlParamsReplace(
    "/packagingAdmins/:adminId/orders?mealtype=:mealType&podId=:podId&date=:date&ageGroupId=:ageGroupId",
    { adminId, mealType, podId, date, ageGroupId }
  );
export const GET_ALL_SCHOOL_ORDERS = (
  adminId,
  offset,
  limit,
  mealType,
  podId,
  date
) =>
  UrlParamsReplace(
    "/packagingAdmins/:adminId/allSchoolOrders?offset=:offset&limit=:limit&mealtype=:mealType&podId=:podId&date=:date",
    { adminId, offset, limit, mealType, podId, date }
  );
export const GET_ALL_SCHOOLS = (adminId) =>
  UrlParamsReplace("/packagingAdmins/:adminId/schools", { adminId });
export const GET_SCHOOL_DETAILS = (
  adminId,
  offset,
  limit,
  schoolId,
  date,
  mealType,
  podId
) =>
  UrlParamsReplace(
    "/packagingAdmins/:adminId/schoolDetails?offset=:offset&limit=:limit&schoolId=:schoolId&date=:date&mealtype=:mealType&podId=:podId",
    { adminId, offset, limit, schoolId, date, mealType, podId }
  );
export const GET_ALL_DELIVERIES = (
  parentId,
  offset,
  limit,
  schoolId,
  date,
  mealType,
  podId
) =>
  UrlParamsReplace(
    "/packagingAdmins/:parentId/deliveries?offset=:offset&limit=:limit&schoolId=:schoolId&date=:date&mealtype=:mealType&podId=:podId",
    { parentId, offset, limit, schoolId, date, mealType, podId }
  );

/****** support team */
// http://localhost:3000/supportTeam/25f94b5d-1d3d-4057-9f35-5c4b4e43acfb/subscriptions?search&offset=0&limit=20

export const GET_SUSCROPTION_LIST_URL = (adminId, search, offset, limit) =>
  UrlParamsReplace(
    "/supportTeam/:adminId/active-subscriptions?search=:search&offset=:offset&limit=:limit",
    { adminId, search, offset, limit }
  );

// http://localhost:3000/supportTeam/25f94b5d-1d3d-4057-9f35-5c4b4e43acfb/subscriptions?search&offset=0&limit=20
export const GET_ALL_SUSCROPTION_LIST_URL = (adminId, search, offset, limit) =>
  UrlParamsReplace(
    "/supportTeam/:adminId/subscriptions?search=:search&offset=:offset&limit=:limit",
    { adminId, search, offset, limit }
  );
// http://localhost:3000/supportTeam/c13bf971-8ef6-4aff-a4b8-70be8ee96506/65e415ab-fb87-4921-bad8-abc7da55fe6a/subscriptions
export const GET_KID_SUBSCRIPTIONS_URL = (adminId, kidId) =>
  UrlParamsReplace("/supportTeam/:adminId/:kidId/subscriptions", {
    adminId,
    kidId,
  });
// supportTeam/c13bf971-8ef6-4aff-a4b8-70be8ee96506/65e415ab-fb87-4921-bad8-abc7da55fe6a/subscriptions
export const GET_SUSCRIPTION_CANCEL_URL = (adminId, kidId, subscriptionId) =>
  UrlParamsReplace(
    "/supportTeam/:adminId/:kidId/subscription/:subscriptionId/cancel",
    { adminId, kidId, subscriptionId }
  );

export const GET_SUSCRIPTION_EXTEND_URL = (adminId, subscriptionId) =>
  UrlParamsReplace(
    "/supportTeam/:adminId/subscription/:subscriptionId/extend",
    { adminId, subscriptionId }
  );
export const GET_SCHOOLS_FOR_SUPPORT = (adminId) =>
  UrlParamsReplace("/supportTeam/:adminId/all-schools", { adminId });
export const GET_ALL_UNDELIVERD_ORDERS_SUPPORT = (adminId) =>
  UrlParamsReplace("/supportTeam/:adminId/orders/undelivered", { adminId });
export const MARK_ALL_ORDERS_DELIVERED_SUPPORT = (adminId) =>
  UrlParamsReplace("/supportTeam/:adminId/orders/mark-delivered", {
    adminId,
  });
export const GET_ALL_UNDISPATCHED_ORDERS_DISPATCH = (adminId) =>
  UrlParamsReplace("/supportTeam/:adminId/orders/undispatched", {
    adminId,
  });
export const GET_ALL_PODS_SUPPORT_TEAM = (adminId) =>
  UrlParamsReplace("/supportTeam/:adminId/pods", {
    adminId,
  });
export const MARK_ALL_ORDERS_DISPATCHED_SUPPORT = (adminId) =>
  UrlParamsReplace("/supportTeam/:adminId/orders/mark-dispatched", {
    adminId,
  });
export const GET_SUSCRIPTION_CANCEL_ORDER_URL = (
  adminId,
  subscriptionId,
  orderId
) =>
  UrlParamsReplace(
    "/supportTeam/:adminId/subscription/:subscriptionId/cancelorder/:orderId",
    { adminId, subscriptionId, orderId }
  );

export const GET_PARENTS_LIST_URL = (
  adminId,
  search,
  offset,
  limit,
  schoolId
) =>
  UrlParamsReplace(
    schoolId == ""
      ? "/supportTeam/:adminId/parents?search=:search&offset=:offset&limit=:limit"
      : "/supportTeam/:adminId/parents?search=:search&offset=:offset&limit=:limit&schoolId=:schoolId",
    { adminId, search, offset, limit, schoolId }
  );
export const GET_KIDS_PARENT_ID_URL = (adminId, parentId) =>
  UrlParamsReplace("/supportTeam/:adminId/parents/:parentId/kids", {
    adminId,
    parentId,
  });
export const GET_KID_ORDER_DETAILS = (
  adminId,
  kidId,
  search,
  page,
  offset,
  limit
) =>
  UrlParamsReplace(
    "/supportTeam/:adminId/kids/:kidId/orders/active?search=:search&page=:page&offset=:offset&limit=:limit",
    { adminId, kidId, page, offset, limit, search }
  );
export const CANCEL_THIS_ORDER = (parentId, kidId, orderId) =>
  UrlParamsReplace("/supportTeam/:parentId/kids/:kidId/orders/:orderId", {
    parentId,
    kidId,
    orderId,
  });
export const GET_SUSCRIPTION_STATUS = (
  parentId,
  kidId,
  subscriptionId,
  date,
  adminId
) =>
  UrlParamsReplace(
    "/supportTeam/:adminId/parents/:parentId/kids/:kidId/orders/subscription/status?subscriptionId=:subscriptionId&date=:date",
    {
      parentId,
      kidId,
      subscriptionId,
      date,
      adminId,
    }
  );
export const CHANGE_MEALPLAN_STATUS = (parentId, kidId, status, adminId) =>
  UrlParamsReplace(
    "/supportTeam/:adminId/parents/:parentId/kids/:kidId/orders/subscription/status",
    {
      parentId,
      kidId,
      status,
      adminId,
    }
  );

export const GET_KID_ORDERS = (adminId, kidId, date, mealType) =>
  UrlParamsReplace(
    "/supportTeam/:adminId/kids/:kidId/orders?date=:date&mealType=:mealType",
    { adminId, kidId, date, mealType }
  );
export const GET_KID_MENUS = (
  adminId,
  parentid,
  kidid,
  date,
  mealType,
  isAfterCutoff
) =>
  UrlParamsReplace(
    "/supportTeam/parents/:parentid/kids/:kidid/subscription/menus/:date?mealType=:mealType&isAfterCutoff=:isAfterCutoff",
    { parentid, kidid, date, mealType, isAfterCutoff }
  );
// UrlParamsReplace(
//   "/supportTeam/:adminId/kids/:kidId/menus?date=:date&mealType=:mealType&isAfterCutoff=:isAfterCutoff",
//   { adminId, kidId, date, mealType, isAfterCutoff }
// );
export const GET_KIDS_LIST_URL = (userId, search, offset, limit, schoolId) =>
  UrlParamsReplace(
    schoolId == ""
      ? "/supportTeam/:userId/kids?limit=:limit&search=:search&offset=:offset"
      : "/supportTeam/:userId/kids?limit=:limit&search=:search&offset=:offset&schoolId=:schoolId",
    { userId, limit, search, offset, schoolId }
  );
export const GET_KID_PAST_ORDER_DETAILS = (
  adminId,
  kidId,
  search,
  page,
  offset,
  limit,
  status
) =>
  UrlParamsReplace(
    "/supportTeam/:adminId/kids/:kidId/orders/past?search=:search&page=:page&offset=:offset&limit=:limit&status=:status",
    { adminId, kidId, page, offset, limit, search, status }
  );

export const GET_SUBSCRIPTION_KID_PAST_ORDER_DETAILS = (
  adminId,
  kidId,
  search,
  page,
  offset,
  limit,
  status
) =>
  UrlParamsReplace(
    "/supportTeam/:adminId/kids/:kidId/orders/subscription/past?search=:search&page=:page&offset=:offset&limit=:limit&status=:status",
    { adminId, kidId, page, offset, limit, search, status }
  );

export const GET_SUBSCRIPTION_CANCEL_ORDER = (adminId, parentId, kidId, date) =>
  UrlParamsReplace(
    "/supportTeam/:adminId/parents/:parentId/kids/:kidId/cancelSubOrder?date=:date",
    { adminId, kidId, parentId, date }
  );
export const REFUND_SUB_ORDER = (parentId, kidId, date, subscriptionId) =>
  UrlParamsReplace(
    "/parents/:parentId/kids/:kidId/refundsuborder?date=:date&subscriptionId=:subscriptionId",
    { parentId, kidId, date, subscriptionId }
  );

export const REFUND_AMOUNT_URL = (adminId) =>
  UrlParamsReplace("/supportTeam/:adminId/refund", { adminId });

export const GET_ORDER_URL = (adminId, orderId, kidId) =>
  UrlParamsReplace("/supportTeam/:adminId/order/:orderId?kidId=:kidId", {
    adminId,
    orderId,
    kidId,
  });
export const GET_ALL_MISSING_ORDERS = (adminId, podId) =>
  UrlParamsReplace("/systemAdmins/:adminId/orders/missing", {
    adminId,
    podId,
  });
export const GET_TRANSACTION_HISTORY_URL = (adminId, offset, limit, search) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/support/transactions?offset=:offset&limit=:limit&search=:search",
    { adminId, offset, limit, search }
  );
export const GET_WALLET = (adminId, kidId) =>
  UrlParamsReplace("/supportTeam/:adminId/kids/:kidId/wallets", {
    adminId,
    kidId,
  });
export const CREATE_ORDER = (adminId, kidId) =>
  UrlParamsReplace("/supportTeam/:adminId/kids/:kidId/orders", {
    adminId,
    kidId,
  });
export const ADD_V2_MENU_ORDER = (adminId, parentId, kidId, isAfterCutoff) =>
  UrlParamsReplace(
    "/supportTeam/:adminId/parents/:parentId/kids/:kidId/orders/subscription?isAfterCutoff=:isAfterCutoff",
    {
      adminId,
      parentId,
      kidId,
      isAfterCutoff,
    }
  );
export const UPGRADE_MENU_ORDER = (adminId, parentId, kidId, isAfterCutoff) =>
  UrlParamsReplace(
    "/supportTeam/:adminId/parents/:parentId/kids/:kidId/orders/upgrademeal?isAfterCutoff=:isAfterCutoff",
    {
      adminId,
      parentId,
      kidId,
      isAfterCutoff,
    }
  );
export const GET_MENU_ITEM = (adminId, menuId) =>
  UrlParamsReplace("/supportTeam/:adminId/getMenu/:menuId", {
    adminId,
    menuId,
  });
export const GET_ORDERS_LIST_URL = (
  adminId,
  search,
  offset,
  limit,
  mealType,
  date,
  podId
) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/orders?search=:search&offset=:offset&limit=:limit&mealtype=:mealType&date=:date&podId=:podId",
    { adminId, search, offset, limit, mealType, date, podId }
  );
export const EXPORT_ORDERS_LIST_URL = (adminId, date, podId) =>
  UrlParamsReplace("/systemAdmins/:adminId/allorders?date=:date&podId=:podId", {
    adminId,
    date,
    podId,
  });
export const GET_ALL_SCHOOLS_FOR_SUPPORT_B2B = (adminId) =>
  UrlParamsReplace("/supportTeam/:adminId/schools", { adminId });
export const UPLOAD_KID_B2B_CSV = (adminId) =>
  UrlParamsReplace("/supportTeam/users/bulk-upload", { adminId });
export const GET_B2B_KIDS_LIST_URL = (
  userId,
  schoolId,
  search,
  offset,
  limit
) =>
  UrlParamsReplace(
    "/supportTeam/:userId/kids/b2b?limit=:limit&search=:search&offset=:offset&schoolId=:schoolId",
    { userId, schoolId, limit, search, offset }
  );
export const UPDATE_KID = (userId, kidId) =>
  UrlParamsReplace("/supportTeam/:userId/kid/:kidId", { userId, kidId });
export const UPDATE_KID_STATUS_B2B = (userId, kidId) =>
  UrlParamsReplace("/supportTeam/:userId/kid-status-b2b/:kidId", {
    userId,
    kidId,
  });
export const UPDATE_KID_STATUS_B2C = (userId, kidId) =>
  UrlParamsReplace("/supportTeam/:userId/kid-status-b2c/:kidId", {
    userId,
    kidId,
  });
export const CHANGE_PARENT_TYPE = (userId) =>
  UrlParamsReplace("/supportTeam/parents/:userId/kids-category", { userId });
export const GET_KIDS_LIST = (userId) =>
  UrlParamsReplace("/supportTeam/:userId/kidList", { userId });
export const GET_ALL_BTC_SCHOOLS = (adminId) =>
  UrlParamsReplace("/supportTeam/:adminId/allBTCSchools", { adminId });
export const GET_BTB_KIDS_ORDERS = (parentId, kidId, offset, limit, date) =>
  UrlParamsReplace(
    "/supportTeam/:parentId/kids/:kidId/btbKidOrders?offset=:offset&limit=:limit&date=:date",
    { parentId, kidId, offset, limit, date }
  );
export const CANCEL_BTB_KID_ORDERS = (parentId, kidId, date) =>
  UrlParamsReplace("/supportTeam/:parentId/kids/:kidId/cancelAll?date=:date", {
    parentId,
    kidId,
    date,
  });
export const GET_MENUS_FOR_PARTICULAR_DATES = (adminId, kidId, date) =>
  UrlParamsReplace("/supportTeam/:adminId/kids/:kidId/allMenu?date=:date", {
    adminId,
    kidId,
    date,
  });
export const CREATE_BTB_ORDER = (parentId, kidId) =>
  UrlParamsReplace("/supportTeam/:parentId/kids/:kidId/kidOrders", {
    parentId,
    kidId,
  });

//TICKETS

export const GET_ALL_TICKETS = (
  adminId,
  date,
  status,
  reason,
  label,
  phoneNumber,
  parentId,
  kidId
) => {
  if (kidId) {
    return UrlParamsReplace(
      "/supportTeam/:adminId/tickets?parentId=:parentId&kidId=:kidId",
      {
        adminId,
        parentId,
        kidId,
      }
    );
  } else if (parentId) {
    return UrlParamsReplace(
      "/supportTeam/:adminId/tickets?parentId=:parentId",
      {
        adminId,
        parentId,
      }
    );
  } else if (phoneNumber) {
    return UrlParamsReplace(
      "/supportTeam/:adminId/tickets?phoneNumber=:phoneNumber",
      {
        adminId,
        phoneNumber,
      }
    );
  } else if (status && reason && label) {
    return UrlParamsReplace(
      "/supportTeam/:adminId/tickets?date=:date&status=:status&reason=:reason&label=:label",
      {
        adminId,
        date,
        status,
        reason,
        label,
      }
    );
  } else if (status && reason) {
    return UrlParamsReplace(
      "/supportTeam/:adminId/tickets?date=:date&status=:status&reason=:reason",
      {
        adminId,
        date,
        status,
        reason,
      }
    );
  } else if (status && label) {
    return UrlParamsReplace(
      "/supportTeam/:adminId/tickets?date=:date&status=:status&label=:label",
      {
        adminId,
        date,
        status,
        label,
      }
    );
  } else if (reason && label) {
    return UrlParamsReplace(
      "/supportTeam/:adminId/tickets?date=:date&reason=:reason&label=:label",
      {
        adminId,
        date,
        reason,
        label,
      }
    );
  } else if (reason) {
    return UrlParamsReplace(
      "/supportTeam/:adminId/tickets?date=:date&reason=:reason",
      {
        adminId,
        date,
        reason,
      }
    );
  } else if (label) {
    return UrlParamsReplace(
      "/supportTeam/:adminId/tickets?date=:date&label=:label",
      {
        adminId,
        date,
        label,
      }
    );
  } else if (status) {
    return UrlParamsReplace(
      "/supportTeam/:adminId/tickets?date=:date&status=:status",
      {
        adminId,
        date,
        status,
      }
    );
  } else {
    return UrlParamsReplace("/supportTeam/:adminId/tickets?date=:date", {
      adminId,
      date,
    });
  }
};

export const GET_ALL_DISTINCT_VALUES = (adminId) =>
  UrlParamsReplace("/supportTeam/:adminId/ticket/distinct-values", {
    adminId,
  });

export const REFRESH_TICKETS = (adminId) =>
  UrlParamsReplace("/supportTeam/:adminId/ticket/refresh", {
    adminId,
  });

export const GET_KID_DETAILS_BY_USERID = (adminId, userId) =>
  UrlParamsReplace("/supportTeam/:adminId/ticket/:userId/details", {
    adminId,
    userId,
  });
export const UPDATE_TICKET_DATA = (adminId, ticketId) =>
  UrlParamsReplace("/supportTeam/:adminId/tickets/:ticketId", {
    adminId,
    ticketId,
  });
export const ADD_TICKET_DATA = (adminId, ticketId) =>
  UrlParamsReplace("/supportTeam/:adminId/ticket", {
    adminId,
  });

// Issue Tracking Analytics

export const GET_ALL_ANALYTICS = (adminId, startDate, endDate) =>
  UrlParamsReplace(
    "/supportTeam/:adminId/tickets/analytics?startDate=:startDate&endDate=:endDate",
    {
      adminId,
      startDate,
      endDate,
    }
  );

// login to customer account
export const LOGIN_TO_CUSTOMER_ACCOUNT = (adminId) =>
  UrlParamsReplace("/supportTeam/:adminId/user-sessions", { adminId });

export const GET_ALL_TRANSACTIONS_LIST = (parentId, kidId) =>
  UrlParamsReplace("/supportTeam/parents/:parentId/kids/:kidId/transactions", {
    parentId,
    kidId,
  });

// Plans
// http://localhost:3000/systemAdmins/c13bf971-8ef6-4aff-a4b8-70be8ee96506/plans
export const GET_PLAN_LIST_URL = (adminId, search) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/plans?search=:search&offset=:offset&limit=:limit",
    { adminId, search }
  );
// http://localhost:3000/systemAdmins/c13bf971-8ef6-4aff-a4b8-70be8ee96506/plans/5f7f1b0e-1b1a-4b1f-8c1a-1b1a4b1f8c1a
export const GET_PLAN_URL = (adminId, planId) =>
  UrlParamsReplace("/systemAdmins/:adminId/plans/:planId", { adminId, planId });
// Add plan
// http://localhost:3000/systemAdmins/c13bf971-8ef6-4aff-a4b8-70be8ee96506/plans
export const ADD_NEW_PLAN = (userId) =>
  UrlParamsReplace("/systemAdmins/:userId/plans", { userId });

export const UPDATE_PLAN = (userId) =>
  UrlParamsReplace("/systemAdmins/:userId/plans", { userId });

export const PLAN_TO_SCHOOLS_MAP = (adminId, planId) =>
  UrlParamsReplace("/systemAdmins/:adminId/plans/:planId", {
    adminId,
    planId,
  });

// get scan time
// Route.withSecurity()
//   .authorize(systemAdminAuth)
//   .get(
//     "/systemAdmins/:adminId/school/scantime/date/:date?deliveryStatus=dispatched",
//     getReports
//   )
//   .bind();

export const SCAN_TIMING_REPORTS_URL = (adminId, date) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/school/scantime/date/:date?deliveryStatus=dispatched",
    {
      adminId,
      date,
    }
  );
export const GET_ALL_DISPATCHED_ORDERS = (adminId, podId) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/orders/all/dispatched-transactions?podId=:podId",
    {
      adminId,
      podId,
    }
  );
export const SCAN_TIMING_DELIEVERD_REPORTS_URL = (
  adminId,
  date,
  status,
  mealType,
  podId
) =>
  UrlParamsReplace(
    `/systemAdmins/:adminId/school/scantime/date/:date?deliveryStatus=${status}&mealType=${mealType}&podId=${podId}`,
    {
      adminId,
      date,
      status,
      mealType,
      podId,
    }
  );

// /packagingAdmins/:adminId/orders/undelivered
export const GET_ALL_UNDELIVERD_DATA_URL = (adminId) =>
  UrlParamsReplace("/packagingAdmins/:adminId/orders/undelivered", {
    adminId,
  });
export const GET_ALL_UNDISPATCHED_DATA_URL = (adminId) =>
  UrlParamsReplace("/packagingAdmins/:adminId/orders/undispatched", {
    adminId,
  });
export const MARK_ALL_ORDERS_DELIVERED = (adminId) =>
  UrlParamsReplace("/packagingAdmins/:adminId/orders/mark-delivered", {
    adminId,
  });
export const MARK_ALL_ORDERS_DISPATCHED = (adminId) =>
  UrlParamsReplace("/packagingAdmins/:adminId/orders/mark-dispatched", {
    adminId,
  });
export const GET_ALL_ROUTE_ORDERS_PACKAGING = (
  adminId,
  offset,
  limit,
  date,
  mealType,
  podId
) =>
  UrlParamsReplace(
    mealType
      ? "/packagingAdmins/:adminId/routeDetails?offset=:offset&limit=:limit&date=:date&mealtype=:mealType&podId=:podId"
      : "/packagingAdmins/:adminId/routeDetails?offset=:offset&limit=:limit&date=:date&podId=:podId",
    { adminId, offset, limit, podId, date, mealType }
  );
export const GET_ALL_MENUS_PACKAGING = (adminId, date) =>
  UrlParamsReplace("/packagingAdmins/:adminId/menus/date/:date", {
    adminId,
    date,
  });
export const GET_ALL_ACTIVE_PODS_PACKAGING = (adminId) =>
  UrlParamsReplace("/packagingAdmins/:adminId/activePods", {
    adminId,
  });
export const GET_PACAKGING_TEAM_POD_ID = (adminId, roleName) =>
  UrlParamsReplace("/packagingAdmins/:adminId/podIds/:roleName", {
    adminId,
    roleName,
  });
export const DOWNLOAD_LABELS_FOR_ORDERS = (
  adminId,
  schoolId,
  date,
  mealtype,
  dispatchStatus
) =>
  UrlParamsReplace(
    "/packagingAdmins/:adminId/schools/:schoolId/delivery/reports?date=:date&mealtype=:mealtype&dispatchStatus=:dispatchStatus",
    {
      adminId,
      schoolId,
      date,
      mealtype,
      dispatchStatus,
    }
  );
export const PRINT_LABELS_FOR_ROUTE_ORDERS = (
  adminId,
  podId,
  mealType,
  date,
  tableRange
) =>
  UrlParamsReplace(
    "/packagingAdmins/:adminId/reports-routewise?date=:date&mealType=:mealType&podId=:podId&tableRange=:tableRange",
    {
      adminId,
      date,
      mealType,
      podId,
      tableRange,
    }
  );

// /systemAdmins/:userId/date/:date/menus-with-rating

export const GET_MENU_RATING = (userId, date) =>
  UrlParamsReplace("/systemAdmins/:userId/date/:date/menus-with-rating", {
    userId,
    date,
  });

// /systemAdmins/:userId/subitems/:subItemId/feedback
export const GET_MENU_RATING_BY_ID_URL = (userId, menuId) =>
  UrlParamsReplace("/systemAdmins/:userId/menus/:menuId/feedback", {
    userId,
    menuId,
  });

// /systemAdmins/:userId/subitems/:subItemId/feedback

export const GET_MENU_RATING_BY_SUBITEMID_URL = (userId, subItemId) =>
  UrlParamsReplace("/systemAdmins/:userId/subitems/:subItemId/feedback", {
    userId,
    subItemId,
  });

// /systemAdmins/:adminId/subscriptions/overlapping
export const GET_OVERLAPPING_SUBSCRIPTION_URL = (adminId) =>
  UrlParamsReplace("/systemAdmins/:adminId/subscriptions/overlapping", {
    adminId,
  });
export const GET_REFERRAL_ALL_HISTORY_URL = (adminId, refferalStatus) =>
  UrlParamsReplace(
    refferalStatus == ""
      ? "/systemAdmins/:adminId/referral/all/history"
      : "/systemAdmins/:adminId/referral/all/history?refferalStatus=:refferalStatus",
    {
      adminId,
      refferalStatus,
    }
  );
export const GET_USER_REFERRAL_HISTORY_URL = (adminId, parentId) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/referral/parents/:parentId/history",
    {
      adminId,
      parentId,
    }
  );
// /systemAdmins/:adminId/referral/rewards

export const GET_REWARDS_CODE_URL = (adminId, userId, date) =>
  UrlParamsReplace(
    userId == ""
      ? "/systemAdmins/:adminId/referral/rewards?date=:date"
      : "/systemAdmins/:adminId/referral/rewards?date=:date&userId=:userId",
    {
      adminId,
      date,
      userId,
    }
  );
export const GET_ALL_REFERRER_URL = (adminId) =>
  UrlParamsReplace("/systemAdmins/:adminId/referral/all/referrer", {
    adminId,
  });
export const GET_REFERRAL_CODE_URL = (adminId) =>
  UrlParamsReplace("/systemAdmins/:adminId/referral/rewards", {
    adminId,
  });

// MIS Report analytics

export const GET_MENUS_MIS_REPORT = (mealType, startDate, endDate, limit) =>
  UrlParamsReplace(
    mealType
      ? "/analytics/top-performance-menus?mealType=:mealType&startDate=:startDate&endDate=:endDate&limit=:limit"
      : "/analytics/top-performance-menus?startDate=:startDate&endDate=:endDate&limit=:limit",
    {
      mealType,
      startDate,
      endDate,
      limit,
    }
  );
export const GET_DSR_REPORT = (date, endDate, podId) =>
  UrlParamsReplace(
    "/analytics/dsr-report?date=:date&endDate=:endDate&podId=:podId",
    {
      date,
      endDate,
      podId,
    }
  );
export const GET_CANCELLED_ORDER_DSR_REPORT = (date, endDate, podId) =>
  UrlParamsReplace(
    "/analytics/cancelled-order-dsr-report?date=:date&endDate=:endDate&podId=:podId",
    {
      date,
      endDate,
      podId,
    }
  );

export const GET_INDIVIDUAL_SCHOOL_DETAILS = (schoolId) =>
  UrlParamsReplace(`/analytics/school-performance-report/${schoolId}`);

export const GET_AGE_GROUP_REPORTS = () =>
  UrlParamsReplace("/analytics/agegroup-report");

export const GET_FINANCE_REPORTS = (startDate, endDate) =>
  UrlParamsReplace(
    "/analytics/finance-dsr?startDate=:startDate&endDate=:endDate",
    {
      startDate,
      endDate,
    }
  );

export const GET_ALL_ROUTE_ORDERS_FINANCE = (
  adminId,
  offset,
  limit,
  date,
  mealType,
  podId
) =>
  UrlParamsReplace(
    mealType
      ? "/financeTeam/:adminId/routeDetails?offset=:offset&limit=:limit&date=:date&mealtype=:mealType&podId=:podId"
      : "/financeTeam/:adminId/routeDetails?offset=:offset&limit=:limit&date=:date&podId=:podId",
    { adminId, offset, limit, podId, date, mealType }
  );

export const GET_SCHOOL_OVERALL_REPORT = (podId) =>
  UrlParamsReplace(
    podId
      ? "/analytics/school-overall-report?podId=:podId"
      : "/analytics/school-overall-report",
    {
      podId,
    }
  );
export const GET_SCHOOL_PERFORMANCE_LIST_REPORT = (podId, startDate, endDate) =>
  UrlParamsReplace(
    podId
      ? "/analytics/school-performance-report?podId=:podId&startDate=:startDate&endDate=:endDate"
      : "/analytics/school-performance-report?startDate=:startDate&endDate=:endDate",
    podId
      ? {
          podId,
          startDate,
          endDate,
        }
      : {
          startDate,
          endDate,
        }
  );

// Coupon code URLS
export const GET_ALL_COUPONS = (adminId, couponType, discountType, usageType) =>
  UrlParamsReplace(
    `/financeTeam/:adminId/coupons?couponType=:couponType&discountType=:discountType&usageType=:usageType`,
    {
      adminId,
      couponType,
      discountType,
      usageType,
    }
  );

export const CREATE_COUPON = (adminId) =>
  UrlParamsReplace(`/financeTeam/:adminId/coupon/generate`, { adminId });

export const UPDATE_COUPON_STATUS = (adminId, couponId) =>
  UrlParamsReplace(`/financeTeam/:adminId/coupon/status/:couponId`, {
    adminId,
    couponId,
  });

export const UPDATE_COUPON = (adminId) =>
  UrlParamsReplace(`/financeTeam/:adminId/coupon/update`, {
    adminId,
  });

export const GET_COUPON_BY_ID = (adminId, id) =>
  UrlParamsReplace(`/financeTeam/:adminId/coupons/:id`, {
    adminId,
    id,
  });

export const GET_KIDS_LIST_FINANCE_URL = (
  userId,
  search,
  offset,
  limit,
  schoolId
) =>
  UrlParamsReplace(
    schoolId == ""
      ? "/financeTeam/:userId/kids?limit=:limit&search=:search&offset=:offset"
      : "/financeTeam/:userId/kids?limit=:limit&search=:search&offset=:offset&schoolId=:schoolId",
    { userId, limit, search, offset, schoolId }
  );

export const GET_SCHOOLS_FINANCE_URL = (userId, offset, limit, search, podId) =>
  UrlParamsReplace(
    "/financeTeam/:userId/schools?offset=:offset&limit=:limit&search=:search&podId=:podId",
    { userId, offset, limit, search, podId }
  );

export const GET_ALL_COUPON_ANALYTICS = (
  startDate,
  endDate,
  groupBySchool,
  couponCode
) =>
  UrlParamsReplace(
    "/analytics/couponusage?startDate=:startDate&endDate=:endDate&groupBySchool=:groupBySchool&couponCode=:couponCode",
    {
      startDate,
      endDate,
      groupBySchool,
      couponCode,
    }
  );

export const GET_SCHOOLS_TRANSACTION_LOGS = (
  startDate,
  endDate,
  offset,
  limit,
  search
) =>
  UrlParamsReplace(
    "/supportTeam/logs?startDate=:startDate&endDate=:endDate&offset=:offset&limit=:limit&search=:search",
    { startDate, endDate, offset, limit, search }
  );

export const UPDATE_WALLET_BALANCE_URL = (adminId, kidId) =>
  UrlParamsReplace("/supportTeam/:adminId/kids/:kidId/wallets", {
    adminId,
    kidId,
  });

export const CREATE_SUBSCRIPTION_URL = (adminId, kidId, parentId) =>
  UrlParamsReplace(
    "/supportTeam/:adminId/parents/:parentId/kids/:kidId/subscription/subscribe",
    { adminId, kidId, parentId }
  );

export const UPDATE_SUBSCRIPTION_URL = (adminId, kidId, subscriptionId) =>
  UrlParamsReplace(
    "/supportTeam/:adminId/kids/:kidId/subscriptions/:subscriptionId",
    { adminId, kidId, subscriptionId }
  );

export const EXTEND_SUBSCRIPTION_URL = (adminId) =>
  UrlParamsReplace("/systemAdmins/:adminId/extendSubscriptions", { adminId });
export const GET_ALL_CAPSMENU = (adminId, startDate, endDate) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/menuordercaps?startDate=:startDate&endDate=:endDate",
    {
      adminId,
      startDate,
      endDate,
    }
  );
export const UPDATE_MENUCAP_QTY = (adminId, startDate, endDate) =>
  UrlParamsReplace("/systemAdmins/:userId/menuordercaps", {
    adminId,
  });

export const GET_ALL_LOYAL_CUSTOMER_URL = (startDate, endDate) =>
  UrlParamsReplace(
    "/analytics/top-loyal-customers?startDate=:startDate&endDate=:endDate",
    {
      startDate,
      endDate,
    }
  );

export const GET_KIDS_BIRTHDAYS = (adminId, date) =>
  UrlParamsReplace("/systemAdmins/:adminId/kidsWithBirthdayAndOrders/:date", {
    adminId,
    date,
  });

export const GET_KIDS_BIRTHDAYS_FOR_FUTURE = (adminId, startDate, endDate) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/kidsWithBirthdays?startDate=:startDate&endDate=:endDate",
    {
      adminId,
      startDate,
      endDate,
    }
  );
export const GET_KIDS_SUBSCRIPTION_REWARDS = (
  adminId,
  status,
  isGiftDelivered
) =>
  UrlParamsReplace(
    "/packagingAdmins/:adminId/subscription-rewards?status=:status&isGiftDelivered=:isGiftDelivered",
    {
      adminId,
      status,
      isGiftDelivered,
    }
  );

export const CHANGE_KIDS_SUBSCRIPTION_REWARDS = (adminId, rewardId) =>
  UrlParamsReplace(
    "/packagingAdmins/:adminId/subscription-rewards/:rewardId/status",
    {
      adminId,
      rewardId,
    }
  );

export const GET_SUBITEMS = (adminId, startDate, endDate, podId) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/exportSubItemList?startDate=:startDate&endDate=:endDate&podId=:podId",
    {
      adminId,
      startDate,
      endDate,
      podId,
    }
  );

export const GET_INVOICES = (adminId, startDate, endDate) =>
  UrlParamsReplace(
    "/systemAdmins/:adminId/netsuite/invoices?startDate=:startDate&endDate=:endDate",
    {
      adminId,
      startDate,
      endDate,
    }
  );

// promotion
export const ADD_PROMOTION_URL = (userId) =>
  UrlParamsReplace("/v3/systemAdmins/:userId/promotions", { userId });
export const DELETE_PROMOTION_URL = (userId, promotionId) =>
  UrlParamsReplace("/v3/systemAdmins/:userId/promotions/:promotionId", {
    userId,
  });

export const UPDATE_PROMOTION_URL = (userId, promotionId) =>
  UrlParamsReplace("/v3/systemAdmins/:userId/promotions/:promotionId", {
    userId,
    promotionId,
  });

export const GET_PROMOTION_URL = (userId, promotionId) =>
  UrlParamsReplace("/v3/systemAdmins/:userId/promotions/:promotionId", {
    userId,
    promotionId,
  });

export const GET_PROMOTIONS_URL = (userId, offset, limit, search) =>
  UrlParamsReplace(
    "/v3/systemAdmins/:userId/promotions?offset=:offset&limit=:limit&search=:search",
    { userId, offset, limit, search }
  );

export const CONTACT_US_LIST_URL = (userId, offset, limit, search) =>
  UrlParamsReplace(
    "/v3/systemAdmins/:userId/contact-us?offset=:offset&limit=:limit&search=:search",
    { userId, offset, limit, search }
  );

//platform data
export const GET_PLATFORM_DATA_REPORT = (
  userId,
  date,
  endDate,
  podId,
  schoolId,
  offset,
  limit
) =>
  UrlParamsReplace(
    "/financeTeam/:userId/platform-fee?startDate=:date&endDate=:endDate&podIds[]=:podId&schoolIds[]=:schoolId&offset=:offset&limit=:limit",
    {
      userId,
      date,
      endDate,
      podId,
      schoolId,
      offset,
      limit,
    }
  );
